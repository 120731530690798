import { Controller } from "@hotwired/stimulus"
import { blobToFile, loadingOverlay } from "../lib/utils"
import { DirectUpload } from "@rails/activestorage"
import $ from "jquery"

export default class extends Controller {
  static targets = ["croppieContainer", "zoomLabel", "rotateLabel", "avatarImg"]
  static values = { directUploadUrl: String }

  declare readonly croppieContainerTarget: HTMLElement
  declare readonly zoomLabelTarget: HTMLElement
  declare readonly rotateLabelTarget: HTMLElement
  declare readonly avatarImgTarget: HTMLImageElement
  declare directUploadUrlValue: string

  private imageUrl: any

  connect() {
    this.resetCropper()
  }

  fileChanged(event) {
    const file = event.currentTarget.files[0]
    if (!file) {
      return
    }

    const fileExtension = file.name.replace(/^.*\./, "")
    if (!["jpg", "jpeg", "png", "svg"].includes(fileExtension.toLocaleLowerCase())) {
      alert("Not valid extension. Supported Extensions are (.png .svg .jpg .jpeg)")
      return
    }

    const file_reader = new FileReader()
    file_reader.readAsDataURL(file)
    file_reader.onload = (event) => {
      this.imageUrl = event.target.result
      this.resetCropper()
    }

    this.element.querySelector(".crop-options").classList.add("active")
  }

  rotate(event) {
    if (event.currentTarget.value > 0) {
      this._cropper().croppie("rotate", -90)
    } else {
      this._cropper().croppie("rotate", 90)
    }
  }

  saveUpload() {
    const size = "{150,150}"

    this._cropper()
      .croppie("result", {
        type: "blob",
        size,
        format: "jpeg",
      })
      .then((canvasData) => {
        loadingOverlay.show()
        new DirectUpload(
          blobToFile(canvasData),
          `${this.directUploadUrlValue}?purpose=logo`,
        ).create((error, blob) => {
          loadingOverlay.hide()
          if (error) {
            alert(error)
          } else {
            this.element.dispatchEvent(
              new CustomEvent("saveUpload", {
                bubbles: true,
                detail: {
                  imageUrl: URL.createObjectURL(canvasData),
                  imageId: blob.signed_id,
                },
              }),
            )
          }
        })
      })
  }

  _cropper() {
    if ($(this.croppieContainerTarget).data("croppie")) {
      return $(this.croppieContainerTarget)
    }
    return this.resetCropper()
  }

  resetCropper() {
    if ($(this.croppieContainerTarget).data("croppie")) {
      $(this.croppieContainerTarget).croppie("destroy")
    }
    return this._initializeCropper(300, 300)
  }

  _initializeCropper(width, height) {
    const cropper = $(this.croppieContainerTarget)
      .croppie({
        enableExif: true,
        viewport: {
          width: width,
          height: height,
        },
        boundary: { width: width + 100, height: height + 100 },
        enableOrientation: true,
        enforceBoundary: true,
      })
      .on("update.croppie", (ev, cropData) => {
        this.zoomLabelTarget.innerText = (Math.round(cropData.zoom * 100) / 100).toString()
        this.rotateLabelTarget.innerText = (90 * ((cropData.orientation - 1) % 4)).toString()
      })

    if (this.imageUrl) {
      cropper.croppie("bind", {
        url: this.imageUrl,
      })
    }
    return cropper
  }
}
