import { Controller } from "@hotwired/stimulus"
import { TurboSubmitStartEvent } from "@hotwired/turbo"

export default class extends Controller<HTMLFormElement> {
  private form: HTMLFormElement

  connect() {
    if (this.element.tagName === "FORM") {
      this.form = this.element as HTMLFormElement
    } else if (this.element.form) {
      this.form = this.element.form
    } else {
      throw new Error("turbo-form controller can only be used with forms and form inputs")
    }
  }

  submit() {
    this.form.requestSubmit()
  }

  validate(event: TurboSubmitStartEvent) {
    const submitter = event.detail.formSubmission.submitter
    if (submitter?.hasAttribute("data-skip-validation")) {
      return
    }

    this.element.classList.add("was-validated")
    if (!this.element.checkValidity()) {
      event.detail.formSubmission.stop()
    }
  }
}
