import { Controller } from "@hotwired/stimulus"
import $ from "jquery"

export default class extends Controller {
  [x: string]: any
  static targets = ["individualBtn", "colorRadio"]
  static values = {
    hexcolor: String,
    inviteUuid: String,
  }

  declare readonly colorRadioTargets: Array<HTMLInputElement>
  declare readonly individualBtnTarget: HTMLElement
  declare hexcolorValue: string

  async updateColor(event) {
    this.hexcolorValue = event.target.value
  }

  hexcolorValueChanged(value: string, oldValue: string) {
    for (const target of this.colorRadioTargets) {
      target.checked = (target.value === value)
    }

    this.individualBtnTarget.style.setProperty("background", value)

    if (!oldValue || value === oldValue) {
      return
    }

    $("#quiz-form").find(`input[name="invite[hex_color]"]`).remove()
    $("#quiz-form").append(`<input type="hidden" name="invite[hex_color]" value="${value}">`)

    $("#own-content-form").find(`input[name="invite[hex_color]"]`).remove()
    $("#own-content-form").append(`<input type="hidden" name="invite[hex_color]" value="${value}">`)

    window.setTimeout(() => {
      let form = (document.getElementById("quiz-form") || document.getElementById("own-content-form")) as HTMLFormElement
      const submitter = document.createElement("button")
      submitter.dataset.skipValidation = "true"
      submitter.hidden = true
      form.append(submitter)
      form.requestSubmit(submitter)
      submitter.remove()
    })
  }
}
