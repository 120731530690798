import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  static values = { uuid: String, modalId: String }
  static targets = ["photoEl", "photoSlider", "personalEl"]

  declare uuidValue: string
  declare modalIdValue: string

  declare readonly photoElTarget: HTMLImageElement
  declare readonly photoSliderTarget: HTMLElement
  declare readonly personalElTarget: HTMLImageElement

  openModal(event) {
    const avatarEl = document.querySelector(".photo-slider > img[data-avatar-id]") as HTMLImageElement
    const modalEl = document.getElementById(this.modalIdValue)

    if (this.modalIdValue === "personal-upload-modal") {
      const imgelement = modalEl.querySelector(
        '.img-holder img[data-personal-upload-target="avatarImg"]',
      ) as HTMLImageElement
      if (imgelement) {
        imgelement.src = avatarEl.src
      }
    }

    const modal = Modal.getOrCreateInstance(modalEl)

    const onSave = (event) => {
      if (this.modalIdValue === "personal-upload-modal") {
        this.personalElTarget.src = event.detail.imageUrl
      } else {
        this.photoElTarget.src = event.detail.imageUrl
      }

      const csrfToken = (document.querySelector("[name='csrf-token']") as HTMLMetaElement).content

      if (this.uuidValue) {
        fetch(`/invites/${this.uuidValue}`, {
          method: "PATCH",
          body: JSON.stringify({ invite: { profile: event.detail.imageId } }),
          headers: { "X-CSRF-Token": csrfToken, "Content-Type": "application/json" },
        }).catch((error) => {
          alert("Error uploading file")
        })
      } else {
        const quizFormElement = document.getElementById("quiz-form")
        if (quizFormElement) {
          quizFormElement.querySelectorAll("input[name='invite[profile]']").forEach((el) => {
            el.remove()
          })
          quizFormElement.insertAdjacentHTML(
            "beforeend",
            `<input type="hidden" name="invite[profile]" value="${event.detail.imageId}">`,
          )
        }
      }
      modal.hide()
    }

    const onModalHidden = () => {
      modalEl.removeEventListener("hidden.bs.modal", onModalHidden)
      modalEl.removeEventListener("saveUpload", onSave)
    }

    modalEl.addEventListener("hidden.bs.modal", onModalHidden)
    modalEl.addEventListener("saveUpload", onSave)

    modal.show()
  }
}
