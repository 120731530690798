import { Controller } from "@hotwired/stimulus"
import $ from "jquery"

export default class extends Controller {
  async toggle(event: InputEvent) {
    const imageOnly = (event.currentTarget as HTMLInputElement).checked

    $("#quiz-form").find(`input[name="invite[avatar_hidden]"]`).remove()
    $("#quiz-form").append(`<input type="hidden" name="invite[avatar_hidden]" value="${imageOnly}">`)

    $("#own-content-form").find(`input[name="invite[avatar_hidden]"]`).remove()
    $("#own-content-form").append(`<input type="hidden" name="invite[avatar_hidden]" value="${imageOnly}">`)

    window.setTimeout(() => {
      let form = (document.getElementById("quiz-form") || document.getElementById("own-content-form")) as HTMLFormElement
      const submitter = document.createElement("button")
      submitter.dataset.skipValidation = "true"
      submitter.hidden = true
      form.append(submitter)
      form.requestSubmit(submitter)
      submitter.remove()
    })
  }
}
