import { Controller } from "@hotwired/stimulus"
import $ from "jquery"

export default class extends Controller<HTMLElement> {
  static values = {
    singlePage: Boolean
  }

  declare singlePageValue: boolean

  toggle() {
    this.singlePageValue = !this.singlePageValue
    $("#quiz-form").find(`input[name="invite[single_page]"]`).remove()
    $("#quiz-form").append(`<input type="hidden" name="invite[single_page]" value="${this.singlePageValue}">`)

    $("#own-content-form").find(`input[name="invite[single_page]"]`).remove()
    $("#own-content-form").append(`<input type="hidden" name="invite[single_page]" value="${this.singlePageValue}">`)

    window.setTimeout(() => {
      let form = (document.getElementById("quiz-form") || document.getElementById("own-content-form")) as HTMLFormElement
      const submitter = document.createElement("button")
      submitter.dataset.skipValidation = "true"
      submitter.hidden = true
      form.append(submitter)
      form.requestSubmit(submitter)
      submitter.remove()
    })
  }
}
