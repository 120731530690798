import {StreamActions, StreamElement} from "@hotwired/turbo"
import { openModalFromTemplate } from "./utils"

StreamActions.modal = function () {
  openModalFromTemplate(this.firstElementChild.innerHTML)
}

StreamActions.redirect_to = function() {
  window.location.href = this.getAttribute("url")
}

StreamActions.dispatch_event = function(this: StreamElement & { templateContent: () => Node }) {
  const target = this.ownerDocument?.querySelector(this.target)
  const type = this.getAttribute("type")

  if (target) {
    target.dispatchEvent(new CustomEvent(type, {
      bubbles: true,
      detail: this.templateContent
    }))
  }
}
